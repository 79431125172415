import { ethers } from 'ethers'
import { sample } from 'lodash'
import { NetworkMetadata } from 'gmx/lib/wallets'
import { isDevelopment } from './env'
import { BigNumber } from 'ethers'

const { parseEther } = ethers.utils

export const BCS_MAINNET = 56
export const BCS_TESTNET = 97
export const ETH_MAINNET = 1
export const AVALANCHE = 43114
export const AVALANCHE_FUJI = 43113
export const ARBITRUM = 42161
export const ARBITRUM_GOERLI = 421613
export const FEES_HIGH_BPS = 50
export const DEFAULT_ALLOWED_SLIPPAGE_BPS = 30
export const ARBITRUM_SEPOLIA = 421614
export const ZKSYNC_SEPOLIA = 300
export const ZKSYNC = 324

export const DEFAULT_NETWORK =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? ZKSYNC : ZKSYNC_SEPOLIA

// TODO take it from web3
export const DEFAULT_CHAIN_ID =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? ZKSYNC : ZKSYNC_SEPOLIA
export const CHAIN_ID = DEFAULT_CHAIN_ID

export const SUPPORTED_CHAIN_IDS =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? [ZKSYNC]
    : [ZKSYNC_SEPOLIA]

if (isDevelopment()) {
  SUPPORTED_CHAIN_IDS.push(ZKSYNC_SEPOLIA)
}

export const IS_NETWORK_DISABLED: Record<number, boolean> = {
  [ARBITRUM]: false,
  [AVALANCHE]: false,
}

export const GAS_PRICE_BUFFER_MAP = {
  [ZKSYNC_SEPOLIA]: BigNumber.from(2000), // 20%
  [ZKSYNC]: BigNumber.from(2000), // 20%
}

export const GAS_PRICE_PREMIUM_MAP = {
  [ZKSYNC_SEPOLIA]: BigNumber.from(3000000000), // 3 gwei
  [ZKSYNC]: BigNumber.from(3000000000), // 3 gwei
}

export const CHAIN_NAMES_MAP: Record<number, string> = {
  [BCS_MAINNET]: 'BSC',
  [BCS_TESTNET]: 'BSC Testnet',
  [ARBITRUM_GOERLI]: 'Arbitrum Goerli',
  [ARBITRUM]: 'Arbitrum',
  [AVALANCHE]: 'Avalanche',
  [AVALANCHE_FUJI]: 'Avalanche Fuji',
  [ZKSYNC_SEPOLIA]: 'ZkSync Sepolia',
  [ZKSYNC]: 'ZkSync',
}

export const NETWORK_EXECUTION_TO_CREATE_FEE_FACTOR = {
  [ARBITRUM]: BigNumber.from(10).pow(29).mul(5),
  [AVALANCHE]: BigNumber.from(10).pow(29).mul(35),
  [AVALANCHE_FUJI]: BigNumber.from(10).pow(29).mul(2),
  [ZKSYNC_SEPOLIA]: BigNumber.from(10).pow(29).mul(5),
  [ZKSYNC]: BigNumber.from(10).pow(29).mul(5),
} as const

export const GAS_PRICE_ADJUSTMENT_MAP: Record<number, string> = {
  [ARBITRUM]: '0',
  [AVALANCHE]: '3000000000', // 3 gwei
  [ZKSYNC_SEPOLIA]: '5000000', // 3 gwei
  [ZKSYNC]: '5000000', // 3 gwei
}

export const MAX_GAS_PRICE_MAP: Record<number, string> = {
  [AVALANCHE]: '200000000000', // 200 gwei
}

export const HIGH_EXECUTION_FEES_MAP: Record<number, number> = {
  [ARBITRUM]: 5, // 3 USD
  [AVALANCHE]: 5, // 3 USD
  [AVALANCHE_FUJI]: 5, // 3 USD
  [ZKSYNC_SEPOLIA]: 3, // 3 USD
  [ZKSYNC]: 3, // 3 USD
}

export const MAX_FEE_PER_GAS_MAP = {
  [ZKSYNC_SEPOLIA]: BigNumber.from(200000000000), // 200 gwei
  [ZKSYNC]: BigNumber.from(200000000000), // 200 gwei
}

export const EXECUTION_FEE_MULTIPLIER_MAP: Record<number, number> = {
  // if gas prices on Arbitrum are high, the main transaction costs would come from the L2 gas usage
  // for executing positions this is around 65,000 gas
  // if gas prices on Ethereum are high, than the gas usage might be higher, this calculation doesn't deal with that
  // case yet
  [ARBITRUM]: 65000,
  // multiplier for Avalanche is just the average gas usage
  [AVALANCHE]: 700000,
  [AVALANCHE_FUJI]: 700000,
  [ZKSYNC_SEPOLIA]: 700000,
  [ZKSYNC]: 700000,
}

export const EXECUTION_FEE_CONFIG_V2: {
  [chainId: number]: {
    shouldUseMaxPriorityFeePerGas: boolean
    defaultBufferBps?: number
  }
} = {
  [AVALANCHE]: {
    shouldUseMaxPriorityFeePerGas: true,
    defaultBufferBps: 1000, // 10%
  },
  [AVALANCHE_FUJI]: {
    shouldUseMaxPriorityFeePerGas: true,
    defaultBufferBps: 1000, // 10%
  },
  [ARBITRUM]: {
    shouldUseMaxPriorityFeePerGas: false,
    defaultBufferBps: 1000, // 10%
  },
  [ARBITRUM_SEPOLIA]: {
    shouldUseMaxPriorityFeePerGas: false,
    defaultBufferBps: 1000, // 10%
  },
  [ZKSYNC_SEPOLIA]: {
    shouldUseMaxPriorityFeePerGas: false,
    defaultBufferBps: 1000, // 10%
  },
  [ZKSYNC]: {
    shouldUseMaxPriorityFeePerGas: false,
    defaultBufferBps: 1000, // 10%
  },
  [ARBITRUM_GOERLI]: {
    shouldUseMaxPriorityFeePerGas: false,
    defaultBufferBps: 1000, // 10%
  },
}

const constants: Record<
  number,
  Record<string, number | string | boolean | BigNumber>
> = {
  [BCS_MAINNET]: {
    nativeTokenSymbol: 'BNB',
    defaultCollateralSymbol: 'BUSD',
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 8,
    v2: false,
  },

  [BCS_TESTNET]: {
    nativeTokenSymbol: 'BNB',
    defaultCollateralSymbol: 'BUSD',
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 8,
    v2: false,
  },

  [ARBITRUM_GOERLI]: {
    nativeTokenSymbol: 'ETH',
    wrappedTokenSymbol: 'WETH',
    defaultCollateralSymbol: 'USDC',
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther('0.0003'),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther('0.0003'),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther('0.000300001'),
  },

  [ARBITRUM]: {
    nativeTokenSymbol: 'ETH',
    wrappedTokenSymbol: 'WETH',
    defaultCollateralSymbol: 'USDC.e',
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther('0.0003'),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther('0.0003'),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther('0.000300001'),
  },

  [AVALANCHE]: {
    nativeTokenSymbol: 'AVAX',
    wrappedTokenSymbol: 'WAVAX',
    defaultCollateralSymbol: 'USDC',
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther('0.01'),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther('0.01'),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther('0.0100001'),
  },

  [AVALANCHE_FUJI]: {
    nativeTokenSymbol: 'AVAX',
    wrappedTokenSymbol: 'WAVAX',
    defaultCollateralSymbol: 'USDC',
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther('0.01'),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther('0.01'),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther('0.0100001'),
  },
}

const ALCHEMY_WHITELISTED_DOMAINS = ['gmx.io', 'app.gmx.io']

export const RPC_PROVIDERS: Record<number, string[]> = {
  [ETH_MAINNET]: ['https://rpc.ankr.com/eth'],
  [BCS_MAINNET]: [
    'https://bsc-dataseed.binance.org',
    'https://bsc-dataseed1.defibit.io',
    'https://bsc-dataseed1.ninicoin.io',
    'https://bsc-dataseed2.defibit.io',
    'https://bsc-dataseed3.defibit.io',
    'https://bsc-dataseed4.defibit.io',
    'https://bsc-dataseed2.ninicoin.io',
    'https://bsc-dataseed3.ninicoin.io',
    'https://bsc-dataseed4.ninicoin.io',
    'https://bsc-dataseed1.binance.org',
    'https://bsc-dataseed2.binance.org',
    'https://bsc-dataseed3.binance.org',
    'https://bsc-dataseed4.binance.org',
  ],
  [BCS_TESTNET]: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
  [ARBITRUM]: ['https://arb1.arbitrum.io/rpc'],
  [ARBITRUM_GOERLI]: [
    'https://goerli-rollup.arbitrum.io/rpc',
    // "https://endpoints.omniatech.io/v1/arbitrum/goerli/public",
    // "https://arbitrum-goerli.public.blastapi.io",
  ],
  [AVALANCHE]: ['https://api.avax.network/ext/bc/C/rpc'],
  [AVALANCHE_FUJI]: [
    'https://avalanche-fuji-c-chain.publicnode.com',
    'https://api.avax-test.network/ext/bc/C/rpc',
    // "https://ava-testnet.public.blastapi.io/v1/avax/fuji/public",
    // "https://rpc.ankr.com/avalanche_fuji",
  ],
  [ARBITRUM_SEPOLIA]: [
    'https://sepolia-rollup.arbitrum.io/rpc',
    'https://sepolia-rollup.arbitrum.io/rpc',
  ],
  [ZKSYNC_SEPOLIA]: [
    'https://zksync-sepolia.g.alchemy.com/v2/RhWq6Mglm8oO_9Wox3lWM8JPiP7ukbxe',
    'https://sepolia.era.zksync.dev',
  ],
  [ZKSYNC]: [
    'https://zksync-mainnet.g.alchemy.com/v2/j0lX58c1BJA579sJxqo4V5v6OYoWhu0Q',
    'https://mainnet.era.zksync.io',
  ],
}

export const FALLBACK_PROVIDERS: Record<number, string[]> = {
  [ARBITRUM]: [getAlchemyHttpUrl()],
  [AVALANCHE]: [
    'https://avax-mainnet.gateway.pokt.network/v1/lb/626f37766c499d003aada23b',
  ],
  [AVALANCHE_FUJI]: [
    'https://endpoints.omniatech.io/v1/avax/fuji/public',
    'https://api.avax-test.network/ext/bc/C/rpc',
    'https://ava-testnet.public.blastapi.io/ext/bc/C/rpc',
  ],
  [ARBITRUM_GOERLI]: [
    'https://arb-goerli.g.alchemy.com/v2/cZfd99JyN42V9Clbs_gOvA3GSBZH1-1j',
  ],
  [ZKSYNC_SEPOLIA]: ['https://sepolia.era.zksync.dev'],
  [ZKSYNC]: [
    'https://zksync.drpc.org',
    'https://mainnet.era.zksync.io',
    'https://zksync.meowrpc.com',
  ],
}

export const NETWORK_METADATA: { [chainId: number]: NetworkMetadata } = {
  [BCS_MAINNET]: {
    chainId: '0x' + BCS_MAINNET.toString(16),
    chainName: 'BSC',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[BCS_MAINNET],
    blockExplorerUrls: ['https://bscscan.com'],
  },
  [BCS_TESTNET]: {
    chainId: '0x' + BCS_TESTNET.toString(16),
    chainName: 'BSC Testnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[BCS_TESTNET],
    blockExplorerUrls: ['https://testnet.bscscan.com/'],
  },
  [ARBITRUM_GOERLI]: {
    chainId: '0x' + ARBITRUM_GOERLI.toString(16),
    chainName: 'Arbitrum Goerli Testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[ARBITRUM_GOERLI],
    blockExplorerUrls: ['https://goerli.arbiscan.io/'],
  },
  [ARBITRUM]: {
    chainId: '0x' + ARBITRUM.toString(16),
    chainName: 'Arbitrum',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[ARBITRUM],
    blockExplorerUrls: [getExplorerUrl(ARBITRUM)],
  },
  [AVALANCHE]: {
    chainId: '0x' + AVALANCHE.toString(16),
    chainName: 'Avalanche',
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[AVALANCHE],
    blockExplorerUrls: [getExplorerUrl(AVALANCHE)],
  },
  [AVALANCHE_FUJI]: {
    chainId: '0x' + AVALANCHE_FUJI.toString(16),
    chainName: 'Avalanche Fuji Testnet',
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[AVALANCHE_FUJI],
    blockExplorerUrls: [getExplorerUrl(AVALANCHE_FUJI)],
  },
}

export const getConstant = (chainId: number, key: string) => {
  if (!constants[chainId]) {
    throw new Error(`Unsupported chainId ${chainId}`)
  }

  if (!(key in constants[chainId])) {
    throw new Error(`Key ${key} does not exist for chainId ${chainId}`)
  }

  return constants[chainId][key]
}

export function getChainName(chainId: number) {
  return CHAIN_NAMES_MAP[chainId]
}

export function getRpcUrl(chainId: number): string | undefined {
  return sample(RPC_PROVIDERS[chainId])
}

export function getFallbackRpcUrl(chainId: number): string | undefined {
  return sample(FALLBACK_PROVIDERS[chainId])
}

export function getAlchemyHttpUrl() {
  if (typeof window === 'undefined') {
    return ''
  }
  if (ALCHEMY_WHITELISTED_DOMAINS.includes(window.location.host)) {
    return 'https://arb-mainnet.g.alchemy.com/v2/RcaXYTizJs51m-w9SnRyDrxSZhE5H9Mf'
  }
  return 'https://arb-mainnet.g.alchemy.com/v2/hxBqIr-vfpJ105JPYLei_ibbJLe66k46'
}

export function getAlchemyWsUrl() {
  if (ALCHEMY_WHITELISTED_DOMAINS.includes(window.location.host)) {
    return 'wss://arb-mainnet.g.alchemy.com/v2/RcaXYTizJs51m-w9SnRyDrxSZhE5H9Mf'
  }
  return 'wss://arb-mainnet.g.alchemy.com/v2/hxBqIr-vfpJ105JPYLei_ibbJLe66k46'
}

export function getExplorerUrl(chainId: number) {
  if (chainId === 3) {
    return 'https://ropsten.etherscan.io/'
  } else if (chainId === 42) {
    return 'https://kovan.etherscan.io/'
  } else if (chainId === BCS_MAINNET) {
    return 'https://bscscan.com/'
  } else if (chainId === BCS_TESTNET) {
    return 'https://testnet.bscscan.com/'
  } else if (chainId === ARBITRUM_GOERLI) {
    return 'https://goerli.arbiscan.io/'
  } else if (chainId === ARBITRUM) {
    return 'https://arbiscan.io/'
  } else if (chainId === AVALANCHE) {
    return 'https://snowtrace.io/'
  } else if (chainId === AVALANCHE_FUJI) {
    return 'https://testnet.snowtrace.io/'
  } else if (chainId === ARBITRUM_SEPOLIA) {
    return 'https://sepolia.arbiscan.io/'
  } else if (chainId === ZKSYNC_SEPOLIA) {
    return 'https://sepolia.explorer.zksync.io/'
  } else if (chainId === ZKSYNC) {
    return 'https://explorer.zksync.io/'
  }
  return 'https://etherscan.io/'
}

export function getHighExecutionFee(chainId: number) {
  return HIGH_EXECUTION_FEES_MAP[chainId] || 5
}

export function getExecutionFeeMultiplier(chainId: number) {
  return EXECUTION_FEE_MULTIPLIER_MAP[chainId] || 1
}

export function isSupportedChain(chainId: number) {
  return SUPPORTED_CHAIN_IDS.includes(chainId)
}

export const MAX_PRIORITY_FEE_PER_GAS_MAP: Record<
  number,
  BigNumber | undefined
> = {
  [ARBITRUM]: BigNumber.from(1500000000),
  [AVALANCHE]: BigNumber.from(1500000000),
  [ZKSYNC_SEPOLIA]: BigNumber.from(1500000000),
  [ZKSYNC]: BigNumber.from(1500000000),
}
